@import 'fonts';
@import 'colours';

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  @extend %font-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $orange;
  color: $white;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 600px;
  max-height: 900px;
  margin: 0 auto;
  position: relative;
}

* {
  box-sizing: border-box;
}
