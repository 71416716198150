.Logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-260%) scale(0.5);
  z-index: 5;
  transition: all 1.5s ease-out;
  opacity: 0;

  &__image {
    width: 200px;
    transition: all 0.500s ease-out;
    opacity: 1;
  }

  // Landing scene
  // &.scene-landing.frame-0 {
  //     opacity: 0;
  //     transform: translateX(-50%) translateY(-50%) scale(0);
  // }

  // &.scene-landing.frame-1 {
  //     opacity: 1;
  //     transform: translateX(-50%) translateY(-50%) scale(1);
  // }

}
