@import 'colours';
@import 'fonts';

.Video {
    padding:177.78% 0 0 0;
    position: absolute;
    transition: all 1s ease-out;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;

    left: 50%;
    background-color: $orange;
    footer {
        width: 100%;
        bottom: 12%;
        opacity: 0;
        color: #fff;
        position: absolute;
        transition: all 0.5s ease-out;

        p {
            @extend %font-medium;
            font-size: 13px;
            line-height: 16px;
            margin: 0;
        }
    }
    &.smallDevice {
      padding:160% 0 0 0;
      width: 85%;
    }
    iframe {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        opacity: 0;
        transition: all 1.2s ease-out;
    }

    h1 {
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        left: 50%;
        top: 30%;
        opacity: 0;
        color: #fff;
        position: absolute;
        margin-top: 100px;
        transition: all 0.5s ease-out;
    }

    &.frame-0 {
        background-color: $orange;

        iframe {
            opacity: 0;
        }
    }

    &.frame-1 {
        background-color: transparent;

        iframe {
            opacity: 1;
        }
    }

    &.frame-2 {
        iframe {
            opacity: 0;
            background-color: transparent;
        }
    }

    &.frame-3 {
        h1, footer {
             opacity: 1;
        }
    }
}
