@font-face {
    font-family: "Futura";
    src: url("Futura-Medium-01.ttf");
}

@font-face {
    font-family: "FuturaBold";
    src: url("Futura-Bold-03.ttf");
}

%font-regular {
  font-family: Futura, sans-serif;
  font-style: normal;
  font-weight: 300;
}

%font-medium {
  font-family: Futura, sans-serif;
  font-style: normal;
  font-weight: 500;
}

%font-bold {
  font-family: FuturaBold, sans-serif;
  font-style: normal;
  font-weight: 700;
}


 h1 {
     @extend %font-medium;
     font-size: 28px;
     text-transform: uppercase;
     line-height: 1;
     text-align: center;
}
