@import 'colours';

.Landing {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    z-index: 5;
    text-align: center;
    pointer-events: none;
}
